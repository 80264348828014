@import '../../theme/theme.less';

.white-box {
  margin-bottom: 20px;
  padding: 20px 20px 0;
  background: @white;
  border-radius: @border-radius-base;
  align-items: center;
}

.bordered-box {
  border-radius: 16px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
}

.display-flex {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1;
}

.filter-box {
  display: flex;
  flex-direction: row;
  .white-box;

  button {
    margin-left: 20px;
    margin-top: 5px;
  }
}

.page-table {
  .white-box;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.spacer {
  width: 20px;
  height: 20px;
}

.form-builder-row {
  display: flex;
  flex-direction: row;
}

.form-builder-box {
  height: 93%;
  max-height: 93%;
  margin: 20px;
  display: flex;

  .box {
    .white-box;
    padding: 0;
    padding-top: 20px;
    flex: 1;
  }

  .bordered-box {
    border-radius: 16px;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
  }

  .bottom-border {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    border-bottom-color: rgb(240, 240, 240);
  }

  .json-editor-container {
    height: calc(100% - 50px);
  }
}

.form-detail-preview .ant-btn:disabled {
  display: none;
}

.order-trace-report-composant-menu-sider {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.order-trace-report-composant-menu {
  background-color: @white !important;
}

.order-trace-report-composant-menu .ant-menu-item-selected {
  background-color: #f2ffb9 !important;
  border-left: 5px solid #ceff00 !important;
}

.order-trace-report-composant-menu .ant-menu-item {
  margin: 0 !important;
  border-left: 5px solid @white;
}

.order-trace-report-step-delimiter.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }
  }
}

.order-trace-report-step-draft.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      text-align: center;

      .ant-steps-icon .ant-steps-icon-dot {
        background-color: @order-trace-step-color-draft !important;
      }
    }

    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }

    .ant-steps-item-content {
      width: 145px !important;
    }
  }
}

.order-trace-report-step-to_be_assigned.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
      background-color: @order-trace-step-color-to_be_assigned !important;
    }

    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }

    .ant-steps-item-content {
      width: 145px !important;
    }
  }
}

.order-trace-report-step-assigned.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
      background-color: @order-trace-step-color-assigned !important;
    }

    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }

    .ant-steps-item-content {
      width: 145px !important;
    }
  }
}

.order-trace-report-step-fulfilled.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
      background-color: @order-trace-step-color-fulfilled !important;
    }

    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }

    .ant-steps-item-content {
      width: 145px !important;
    }
  }
}

.order-trace-report-step-completed.ant-steps-item {
  .ant-steps-item-container {
    .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
      background-color: @order-trace-step-color-completed !important;
    }

    .ant-steps-item-tail::after {
      height: 0;
      border: 1px dashed #096dd9;
      background-color: #f5f5f5;
    }

    .ant-steps-item-content {
      width: 145px !important;
    }
  }
}

.orders-report-table .ant-table-row-selected .ant-table-cell {
  background-color: white !important;
}

.orders-report-table .ant-table-cell > .ant-dropdown-button > button:first-child {
  display: none;
}


.admin-page {
  .white-box;
  padding: 20px 0 0;
}

.admin-box {
  padding: 0 20px;
  margin-top: 30px;
}

.admin-box.grey {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  padding-left: 0;
}

.admin-box .supply-chain-col {
  padding: 20px 0;
  flex: 1;
}

.admin-box .supply-chain-col.list {
  border-right: 1px solid #efefef;
  margin-right: 30px;
  min-width: 250px;
  max-width: 250px;
  padding-top: 0;
}

.admin-box .supply-chain-col.list .ant-btn {
  padding: 0;
}

.admin-box .supply-chain-col.list .supply-element {
  padding: 10px 20px;
  position: relative;
}

.admin-box .supply-chain-col.list .supply-element.selected {
  background-color: #e6f7ff;
}

.admin-box .supply-chain-col.list .supply-element .border {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1890ff;
  width: 5px;
  height: 100%;
  display: none;
}

.admin-box .supply-chain-col.list .supply-element.selected .border {
  display: block;
}

.admin-box .supply-chain-col.list .ant-btn.active {
  color: #1890ff;
}

.admin-box .supply-chain-col.list .ant-btn.inactive {
  color: #bfbfbf;
}

.tabs-padding .ant-tabs-nav {
  margin-left: 20px;
}

.sc-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  cursor: move;
}

.sc-step-number {
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sc-step-number-ball {
  width: 12px;
  height: 12px;
  border: 2px solid #1890ff;
  border-radius: 10px;
  margin-right: 10px;
}

.sc-step-box {
  border: 1px solid #cfcfcf;
  background-color: #f2f2f2;
  border-radius: 5px;
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
}

.sc-modal-input-box {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 10px;
}

.sc-modal-steps {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 15px !important;
}

.sc-modal-step-box {
  border: 1px solid #cfcfcf;
  background-color: #f2f2f2;
  padding: 15px;
  cursor: pointer;
}

.sc-modal-step-box.selected {
  background-color: #333;
}

.sc-modal-step-box.selected .ant-typography {
  color: #fff;
}

.ant-btn:disabled {
  opacity: 0.5;
}

.sc-rule-box {
  border: 1px solid #cfcfcf;
  background-color: #f2f2f2;
  border-radius: 5px;
  flex: 1;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 10px;
}

.sc-rule-col {
  flex: 1;
}

.sc-rule-col.ml {
  margin-left: 15px;
}

.sc-rule-col.counter {
  flex: 0;
  margin-left: 15px;
  min-width: 150px;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.sc-rule-col.action {
  flex: 0;
  margin-left: 15px;
  min-width: 100px;
  max-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.sc-ball {
  min-width: 37px;
  max-width: 37px;
  background-color: #333;
  margin-left: 10px;
  height: 37px;
  border-radius: 20px;
  line-height: 37px;
  text-align: center;
}

.sc-ball.view {
  line-height: 41px;
  text-align: left;
}

.sc-ball.counter {
  background-color: #096dd9;
}

.sc-rule-detail .ant-modal-body {
  padding-bottom: 8px;
}

.sc-rule-detail-box {
  border: 1px solid #cfcfcf;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.sc-rule-detail-box-column {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ant-menu-inline .ant-menu-item {
  padding-left: 16px !important;
}

.sc-modal-rule .ant-form-item-explain div {
  display: none;
}

.trace_type_order {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: rgb(239, 239, 239);
  border-radius: 10px;
  box-shadow: rgb(0 0 0) 0 0 3px -1px;
  padding: 10px 10px 10px 15px;
}

.trace_type_order > div.trace_type_order_cell {
  padding-left:10px;
  display: flex;
  flex-direction: column;
}

.trace_type_order > div.trace_type_order_cell > span.trace_type_order_label {
  height: 20px;
}

.trace_type_order > div.trace_type_order_cell > span.trace_type_order_label, .trace_type_order > div.trace_type_order_cell > span.trace_type_order_label * {
  color: #A1A1A1;
  font-size: 12px;
}

.trace_type_order > div.trace_type_order_cell > div.trace_type_order_content {
  flex-grow: 1;
  font-size: 16px;
  border-right: 1px solid #C7C7C7;
}

.trace_type_order > div.trace_type_order_cell:last-child > div.trace_type_order_content,
.trace_type_order > div.trace_type_order_cell.last > div.trace_type_order_content{
  border-right: 0;
}

.trace_type_order > div:first-child {
  padding-left:0;
}

.ant-layout-sider-collapsed .menu-label {
  display: none;
}

.notificationList .ant-list-lg .ant-list-item{
  padding : 0
}

.notificationPopup{
  padding: 0;
  width: 400px;
}
.notificationPopup .ant-notification-notice-message{
  padding: 0;
  margin: 0;
}

.noticeCard{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.noticeCardIcon{
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noticeCardIcon img{
  width: 20px;
  height: 20px;
}
.noticeCardData{
  flex: fit-content ;
  flex-direction: column;
}
.noticeCardHeader{
  display: flex;
  flex-direction: row;
}
.noticeCardTitle{
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: bold;
}
.noticeCardMarkAsRead{
  min-height: 38px;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
}

.noticeCardBody{
  padding-left: 10px;
  min-height: 40px;
}

.noticeCardBody a{
  text-decoration: underline;
}

.noticeCardFooter{
  display: flex;
  // align-content: flex-end;
  // justify-content: flex-end;
  // justify-items: flex-end;

}
.noticeCardFooter * {
  color: #4f4f4f;
}

.notificatiinListHeader{
  flex: fit-content ;
  flex-direction: column;
}

.notificatoinListBody{
  //height: 50%;
}

.notificatoinListdFooter{
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
}

.notificationLabelPadding{
  padding: 10px;
}

.blueLink, .blueLink:hover {
  color: blue;
  text-decoration: underline;
}

.trace_hide {
  color: #b0b0b0;
}

.order_log_citation {
  white-space: pre-wrap;
  font-style: italic;
  margin-top: 10px;
}

.order_log_citation:before, .order_log_citation:after  {
  content: '"'
}

.appForm label:before {
  content: ""
}

.tyny-progress-style-col-card .ant-card-body{
  height: 440px;
  overflow: auto;
}

.ant-form-item-has-error > div.ant-form-item-control > div.ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-affix-wrapper,
.ant-form-item-has-error > div.ant-form-item-control > div.ant-form-item-control-input > .ant-form-item-control-input-content > .ant-btn,
.ant-form-item-has-error > div.ant-form-item-control > div.ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-number,
.ant-form-item-has-error > div.ant-form-item-control > div.ant-form-item-control-input > .ant-form-item-control-input-content > div > .ant-select-selector,
.ant-form-item-has-error > div.ant-form-item-control > div.ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input {
  border-color: #ff4d4f!important;
}
